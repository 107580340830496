import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { set } from 'lodash-es';
import { Message, Segment, Grid } from 'semantic-ui-react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, Redirect, useHistory } from 'react-router-dom';
import { Breadcrumbs, ErrorMessage, Layout } from 'components';
import { request } from 'utils/api';
import { Link } from 'react-router-dom';
import {
  Container,
  Form,
  Table,
  Header,
  Divider,
  HeaderSubheader,
  Label,
  Button,
} from 'semantic';
import SelectionReviewModal from 'components/BulkActionsIsland/SelectionReviewModal';
import {
  CHARGING_STATIONS_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH,
  EVSE_BULK_ACTIONS_BE_CHANGE_CONFIGURATION_PATH,
} from '../utils';
import { useToast, ToastButtonLayout } from 'components/Toast';
import { getEvseProtocols } from 'utils/evse-controllers';
import useFetch from 'hooks/useFetch';
import {
  configurationKeysFromProtocolResponses,
  ProtocolResponse16,
  ProtocolResponse201,
  StandardConfigurationKey,
  StandardConfigurationKeys,
} from './protocol';
import ConfigurationInput, {
  CONFIGURATION_INPUT_TYPE_CUSTOM,
  CONFIGURATION_INPUT_TYPE_STANDARD,
  ConfigurationEntry,
} from './ConfigurationInput';
import { shortCircuitEvent } from 'utils/events';
import { EvseController } from 'types/evse-controller';

const selectionReviewEqual = (a, b) => a.id === b.id;

const selectionReviewColumns = [
  {
    title: <Table.HeaderCell>OCPP ID</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.ocppIdentity}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>Account</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.account?.name || '-'}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>OCPP Version</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.ocppProtocolVersion || '-'}</Table.Cell>
    ),
  },
];

type LocationState = {
  evseControllers?: any[];
};

type ViewState = {
  loading: boolean;
  apiError: Error | null;
  formError: Error | null;
};

type BulkChangeConfigurationForm = {
  chargingStationIds: string[];
  configurationItems: BulkChangeConfigurationItem[];
  note: string;
};

type BulkChangeConfigurationItem = {
  id: string;
  name: string;
  value: string;
  component?: {
    name: string;
  };
  ocppProtocolVersion: string;
  label: string;
  input: 'standard' | 'custom';
  standardKeyId?: string;
  edit: boolean;
};

export default function EvseControllersBackgroundJobsChangeConfiguration() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [viewState, setViewState] = useState<ViewState>({
    loading: false,
    apiError: null,
    formError: null,
  });

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const { evseControllers } = (location.state || {}) as LocationState;

  const [selectedItems, setSelectedItems] = useState(evseControllers || []);

  const { data: protocol16 } = useFetch<ProtocolResponse16>({
    path: '1/ocpp/1.6/protocol',
  });

  const { data: protocol201 } = useFetch<ProtocolResponse201>({
    path: '1/ocpp/2.0.1/protocol',
  });

  const [ocppProtocols, setOcppProtocols] = useState<string[]>(
    getEvseProtocols(selectedItems)
  );

  const standardConfigurationKeys = useMemo<StandardConfigurationKeys>(() => {
    return configurationKeysFromProtocolResponses(
      ocppProtocols,
      protocol16,
      protocol201
    );
  }, [ocppProtocols, protocol16, protocol201]);

  const [bulkChangeConfiguration, setBulkChangeConfiguration] =
    useState<BulkChangeConfigurationForm>({
      note: '',
      configurationItems: [],
      chargingStationIds: selectedItems.map(
        (item) => item.ocppChargingStationId
      ),
    });

  const setField = (name: string, value: any, nullable = false) => {
    const parsedValue = value === '' && nullable ? null : value;
    setBulkChangeConfiguration(
      set({ ...bulkChangeConfiguration }, name, parsedValue)
    );
  };

  const updatedSelectedItems = (items: EvseController[]) => {
    setSelectedItems(items);
    setField(
      'chargingStationIds',
      items.map((item) => item.ocppChargingStationId)
    );
    setOcppProtocols(getEvseProtocols(items));
  };

  const submitForm = useCallback(async () => {
    setViewState({ loading: true, apiError: null, formError: null });

    if (!bulkChangeConfiguration.chargingStationIds.length) {
      setViewState({
        loading: false,
        apiError: null,
        formError: new Error('At least one charging station is required'),
      });
      return;
    }
    if (!bulkChangeConfiguration.configurationItems.length) {
      setViewState({
        loading: false,
        apiError: null,
        formError: new Error('At least one configuration item is required'),
      });
      return;
    }

    try {
      const { data } = await request({
        method: 'POST',
        path: EVSE_BULK_ACTIONS_BE_CHANGE_CONFIGURATION_PATH,
        body: {
          ...bulkChangeConfiguration,
          // Remove non-API fields
          configurationItems: bulkChangeConfiguration.configurationItems.map(
            (item) => ({
              ...item,
              id: undefined,
              label: undefined,
              input: undefined,
              standardKeyId: undefined,
              edit: undefined,
            })
          ),
        },
      });

      toast.info(
        <ToastButtonLayout
          buttonTo={`${EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH}/${data.id}`}
          buttonTitle={t(
            'evseControllersBackgroundJobs.successToastView',
            'View'
          )}>
          <Trans
            i18nKey="evseControllersBackgroundJobsChangeConfiguration.toastSuccess"
            defaults="Job {{jobId}} <strong>Change Configuration</strong> is in progress. Go to Background Jobs page for details."
            values={{ jobId: data.id }}
          />
        </ToastButtonLayout>
      );
      setViewState({ loading: false, apiError: null, formError: null });
      history.push(CHARGING_STATIONS_FE_PATH);
    } catch (e: any) {
      setViewState({ loading: false, apiError: e, formError: null });
    }
  }, [bulkChangeConfiguration]);

  return selectedItems.length === 0 ? (
    <Redirect to={CHARGING_STATIONS_FE_PATH} />
  ) : (
    <Container>
      <Breadcrumbs
        path={[
          <Link key="backgroundjobs" to={CHARGING_STATIONS_FE_PATH}>
            {t(
              'evseControllersBackgroundJobsChangeConfiguration.breadcrumbsChargingStations',
              'Charging Stations'
            )}
          </Link>,
        ]}
        active={t(
          'evseControllersBackgroundJobsChangeConfiguration.title',
          'Change OCPP Configuration'
        )}
      />
      <Header as="h2">
        {t(
          'evseControllersBackgroundJobsChangeConfiguration.title',
          'Change OCPP Configuration'
        )}
      </Header>
      <Divider hidden />

      <Form error={Boolean(viewState.formError)} onSubmit={submitForm}>
        <div>
          <SelectionReviewModal
            isOpen={isReviewModalOpen}
            onSetIsOpen={setIsReviewModalOpen}
            selectedItems={selectedItems}
            onSetSelectedItems={updatedSelectedItems}
            selectionReviewColumns={selectionReviewColumns}
            equal={selectionReviewEqual}
          />
          <Header as="h4">
            {t(
              'evseControllersBackgroundJobsChangeConfiguration.reviewChargingStationTitle',
              '1. Review your selected charging station'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseControllersBackgroundJobsChangeConfiguration.reviewChargingStationSubTitle',
                'Once saved, the changes cannot be cancelled or undone.'
              )}
            </HeaderSubheader>
          </Header>
          <Segment>
            <Label
              style={{ padding: '0.95em', margin: 0 }}
              content={`${selectedItems?.length} ${t(
                'evseControllersBackgroundJobsChangeConfiguration.chargingStations',
                'charging stations'
              )}`}
            />
            <Button
              basic
              content={t(
                'evseControllersBackgroundJobsChangeConfiguration.showSelectedChargingStations',
                'Show Selected'
              )}
              onClick={() => setIsReviewModalOpen(true)}
              type="button"
              icon="list"
            />
          </Segment>

          {ocppProtocols.length > 1 && (
            <Message>
              {t(
                'evseControllersBackgroundJobsChangeConfiguration.multiProtocolWarning',
                'Note: Different OCPP versions for charging stations detected. Only the variables (keys) for the relevant OCPP version will be updated.'
              )}
            </Message>
          )}
        </div>

        <Divider hidden />

        <div>
          <Header as="h4">
            {t(
              'evseControllersBackgroundJobsChangeConfiguration.defineConfigurationTitle',
              '2. Define OCPP Configuration'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseControllersBackgroundJobsChangeConfiguration.defineConfigurationSubTitle',
                'Choose an OCPP Configuration variable (key) you want to change, or create a new one. You can add and create multiple variables (keys).'
              )}
            </HeaderSubheader>
          </Header>

          {bulkChangeConfiguration.configurationItems?.map((item, idx) => (
            <Segment key={item.id}>
              {item.edit && (
                <ConfigurationInput
                  standardConfigurationKeys={standardConfigurationKeys}
                  ocppProtocols={ocppProtocols}
                  addText={t(
                    'evseControllersBackgroundJobsChangeConfiguration.editConfiguration',
                    'Finish editing'
                  )}
                  addIcon={'pen-to-square'}
                  onSave={(entry: ConfigurationEntry) => {
                    const configurationItems = [
                      ...bulkChangeConfiguration.configurationItems,
                    ];
                    configurationItems[idx] = mapConfigurationEntry(entry);
                    setField('configurationItems', configurationItems);
                  }}
                  inputType={item.input}
                  standardConfigurationValue={
                    item.input === CONFIGURATION_INPUT_TYPE_STANDARD
                      ? {
                          key: standardConfigurationKeys[
                            item.standardKeyId as string
                          ],
                          value: item.value,
                        }
                      : undefined
                  }
                  customConfigurationValue={
                    item.input === CONFIGURATION_INPUT_TYPE_CUSTOM
                      ? {
                          key: item.name,
                          component: item.component?.name,
                          value: item.value,
                        }
                      : undefined
                  }
                />
              )}
              {!item.edit && (
                <Layout horizontal spread>
                  <div>
                    <Label
                      content={`${
                        item.input === CONFIGURATION_INPUT_TYPE_STANDARD
                          ? t(
                              'evseControllersBackgroundJobsChangeConfiguration.addedStandardVariableKeyLabel',
                              'Standard Variable (Key)'
                            )
                          : t(
                              'evseControllersBackgroundJobsChangeConfiguration.addedCustomVariableKeyLabel',
                              'Custom Variable (Key)'
                            )
                      }: ${item.label}${ocppProtocols.length > 1 ? ` OCPP ${item.ocppProtocolVersion === 'ocpp1.6' ? '1.6' : '2.0.1'}` : ''}`}
                      style={{ backgroundColor: 'aliceblue' }}
                    />
                    <Label content={`Value: ${item.value}`} />
                  </div>
                  <div>
                    <Button
                      basic
                      icon="pen-to-square"
                      title="Edit"
                      onClick={(e: React.SyntheticEvent<HTMLElement>) => {
                        shortCircuitEvent(e);
                        const configurationItems = [
                          ...bulkChangeConfiguration.configurationItems,
                        ];
                        configurationItems[idx] = {
                          ...configurationItems[idx],
                          edit: true,
                        };
                        setField('configurationItems', configurationItems);
                      }}
                    />
                    <Button
                      basic
                      icon="trash"
                      title="Delete"
                      onClick={(e: React.SyntheticEvent<HTMLElement>) => {
                        shortCircuitEvent(e);
                        const configurationItems = [
                          ...bulkChangeConfiguration.configurationItems,
                        ];
                        configurationItems.splice(idx, 1);
                        setField('configurationItems', configurationItems);
                      }}
                    />
                  </div>
                </Layout>
              )}
            </Segment>
          ))}

          <ConfigurationInput
            standardConfigurationKeys={standardConfigurationKeys}
            ocppProtocols={ocppProtocols}
            addText={t(
              'evseControllersBackgroundJobsChangeConfiguration.addConfiguration',
              'Save and add more configuration'
            )}
            onSave={(entry: ConfigurationEntry) =>
              setField('configurationItems', [
                ...bulkChangeConfiguration.configurationItems,
                mapConfigurationEntry(entry),
              ])
            }
          />
        </div>

        <Divider hidden />

        <div>
          <Header as="h4">
            {t(
              'evseControllersBackgroundJobsChangeConfiguration.addCustomNoteTitle',
              '3. Add custom note'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseControllersBackgroundJobsChangeConfiguration.addCustomNoteSubTitle',
                'Write a note to show together with the details of this command at the Background Jobs page.'
              )}
            </HeaderSubheader>
          </Header>
          <Segment>
            <Form.Input
              style={{ width: '50%' }}
              name="note"
              placeholder={t(
                'evseControllersBackgroundJobsChangeConfiguration.addCustomNotePlaceholder',
                'Type here...'
              )}
              label={t(
                'evseControllersBackgroundJobsChangeConfiguration.addCustomNoteInputLabel',
                'Note'
              )}
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />
          </Segment>
        </div>

        <Divider />

        <ErrorMessage error={viewState.apiError} />
        <ErrorMessage error={viewState.formError} />

        <Button
          loading={viewState.loading}
          disabled={viewState.loading}
          content={t(
            'evseControllersBackgroundJobsChangeConfiguration.executeChangeConfiguration',
            'Change Configuration'
          )}
          type="submit"
        />

        <Link to={viewState.loading ? '#' : '/charging-stations'}>
          <Button
            disabled={viewState.loading}
            content={t(
              'evseControllersBackgroundJobsChangeConfiguration.cancelUpdate',
              'Cancel'
            )}
            basic
            type="button"
          />
        </Link>
      </Form>
    </Container>
  );
}

function mapConfigurationEntry(
  entry: ConfigurationEntry
): BulkChangeConfigurationItem {
  return {
    id: [entry.ocppProtocol, entry.component, entry.name]
      .filter((v) => v)
      .join('.'),
    name: entry.name,
    value: entry.value,
    ...(entry.component && {
      component: {
        name: entry.component,
      },
    }),
    ocppProtocolVersion: entry.ocppProtocol,
    label: entry.label,
    input: entry.input,
    standardKeyId: entry.standardKeyId,
    edit: false,
  };
}
